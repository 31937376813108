// Generated by Framer (6906a2c)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";

const cycleOrder = ["Bqlr7g2kq", "gCKthQ4E5"];

const variantClassNames = {"Bqlr7g2kq": "framer-v-meu42", "gCKthQ4E5": "framer-v-1fs7eiu"};

const humanReadableVariantMap = {"Variant 1": "Bqlr7g2kq", "Rotated": "gCKthQ4E5"};

const transitions = {"default": {"type": "spring", "ease": [0, 0, 1, 1], "duration": 0.3, "delay": 0, "stiffness": 500, "damping": 60, "mass": 1}, "gCKthQ4E5": {"type": "spring", "ease": [0.44, 0, 0.56, 1], "duration": 0.3, "delay": 0, "stiffness": 500, "damping": 60, "mass": 1}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: string; tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style, className, width, height, layoutId, variant: outerVariant = "Bqlr7g2kq", image: Z4S9ZLLoB = new URL("assets/512/SYi5SqgCufMfnYz3WXGfQ90fUvM.png", import.meta.url).href, tap: YIJXKXMmj, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "Bqlr7g2kq", variant, transitions, variantClassNames, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant);

const onTapmeu42 = activeVariantCallback(async (...args) => {
if (YIJXKXMmj) {
const res = await YIJXKXMmj(...args);
if (res === false) return false;
}
})

const onMouseEntermeu42 = activeVariantCallback(async (...args) => {
setVariant("gCKthQ4E5")
})

const onTap1fs7eiu = activeVariantCallback(async (...args) => {
if (YIJXKXMmj) {
const res = await YIJXKXMmj(...args);
if (res === false) return false;
}
})

const onMouseLeave1fs7eiu = activeVariantCallback(async (...args) => {
setVariant("Bqlr7g2kq")
})

const variantProps = React.useMemo(() => ({"gCKthQ4E5": {"Bqlr7g2kq": {"onTap": onTap1fs7eiu, "onMouseLeave": onMouseLeave1fs7eiu, "onMouseEnter": undefined, "data-framer-name": "Rotated", "background": null, "data-highlight": true}, "CLrke3yRQ": {"background": {"src": Z4S9ZLLoB, "fit": "fill"}}}}), [Z4S9ZLLoB, onTap1fs7eiu, onMouseLeave1fs7eiu]);

const addVariantProps = useAddVariantProps(baseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qtlZw", classNames)} style={{"display": "contents"}}>
<motion.div {...restProps} layoutId="Bqlr7g2kq" className={cx("framer-meu42", className)} style={{"rotate": 0, ...style}}  background={null} data-highlight={true} data-framer-name="Variant 1" onTap={onTapmeu42} onMouseEnter={onMouseEntermeu42} variants={{"gCKthQ4E5": {"rotate": 360}}} transition={transition} layoutDependency={layoutDependency} ref={ref} {...addVariantProps("Bqlr7g2kq")}><Image layoutId="CLrke3yRQ" className="framer-15qia1h" style={{"rotate": 0}}  background={{"src": Z4S9ZLLoB, "fit": "fill"}} alt="" variants={{"gCKthQ4E5": {"rotate": 359}}} transition={transition} layoutDependency={layoutDependency} {...addVariantProps("CLrke3yRQ")}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qtlZw [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qtlZw * { box-sizing: border-box; }", ".framer-qtlZw .framer-meu42 { position: relative; cursor: pointer; overflow: visible; width: 240px; height: 240px; }", ".framer-qtlZw .framer-15qia1h { position: absolute; overflow: visible; height: var(--framer-aspect-ratio-supported, 240px); right: 0px; left: 0px; top: 0px; flex: none; aspect-ratio: 1 / 1; }", ".framer-qtlZw.framer-v-1fs7eiu .framer-meu42 { width: 240px; height: 240px; cursor: pointer; }", ".framer-qtlZw.framer-v-1fs7eiu .framer-15qia1h { width: auto; height: var(--framer-aspect-ratio-supported, 200px); right: 20px; bottom: auto; left: 20px; top: 20px; flex: none; aspect-ratio: 1 / 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 240
 * @framerIntrinsicWidth 240
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}, "gCKthQ4E5": {"layout": ["fixed", "fixed"]}}}
 * @framerVariables {"Z4S9ZLLoB": "image", "YIJXKXMmj": "tap"}
 */
const FramervF3KYFVj4: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramervF3KYFVj4;

FramervF3KYFVj4.displayName = "Rotating Button";

FramervF3KYFVj4.defaultProps = {"width": 240, "height": 240};

addPropertyControls(FramervF3KYFVj4, {"variant": {"type": ControlType.Enum, "title": "Variant", "options": ["Bqlr7g2kq", "gCKthQ4E5"], "optionTitles": ["Variant 1", "Rotated"]}, "Z4S9ZLLoB": {"type": ControlType.Image, "title": "Image", "__defaultAssetReference": "SYi5SqgCufMfnYz3WXGfQ90fUvM.png"}, "YIJXKXMmj": {"type": ControlType.EventHandler, "title": "Tap"}} as any);

addFonts(FramervF3KYFVj4, []);